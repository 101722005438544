exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-5-yearsdiary-1-tsx": () => import("./../../../src/pages/5yearsdiary1.tsx" /* webpackChunkName: "component---src-pages-5-yearsdiary-1-tsx" */),
  "component---src-pages-attention-1-tsx": () => import("./../../../src/pages/attention1.tsx" /* webpackChunkName: "component---src-pages-attention-1-tsx" */),
  "component---src-pages-attention-2-tsx": () => import("./../../../src/pages/attention2.tsx" /* webpackChunkName: "component---src-pages-attention-2-tsx" */),
  "component---src-pages-attention-3-tsx": () => import("./../../../src/pages/attention3.tsx" /* webpackChunkName: "component---src-pages-attention-3-tsx" */),
  "component---src-pages-contact-1-tsx": () => import("./../../../src/pages/contact1.tsx" /* webpackChunkName: "component---src-pages-contact-1-tsx" */),
  "component---src-pages-corporate-1-tsx": () => import("./../../../src/pages/corporate1.tsx" /* webpackChunkName: "component---src-pages-corporate-1-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-detail-tsx": () => import("./../../../src/pages/news/detail.tsx" /* webpackChunkName: "component---src-pages-news-detail-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-profile-1-tsx": () => import("./../../../src/pages/profile1.tsx" /* webpackChunkName: "component---src-pages-profile-1-tsx" */),
  "component---src-pages-service-1-tsx": () => import("./../../../src/pages/service1.tsx" /* webpackChunkName: "component---src-pages-service-1-tsx" */),
  "component---src-pages-student-tsx": () => import("./../../../src/pages/student.tsx" /* webpackChunkName: "component---src-pages-student-tsx" */)
}

